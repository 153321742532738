import https from "./https"
import store from "@/store"

const organization = store.state.general.organization;

const collection = {
  getCollections(params) {
    return https.get(`admin/organizations/${organization.id}/collections`, { params });
  },
  deleteCollection(id) {
    return https.delete(`admin/organizations/${organization.id}/collections/${id}`);
  },
  createCollection(params) {
    return https.post(`admin/organizations/${organization.id}/collections`, params);
  },
  updateCollection(id, params) {
    return https.put(`admin/organizations/${organization.id}/collections/${id}`, params);
  },
  getCollection(id) {
    return https.get(`admin/organizations/${organization.id}/collections/${id}`);
  },
  getItems(id, params) {
    return https.get(`admin/organizations/${organization.id}/collections/${id}/items`, { params });
  },
  createItem(collectionId, params) {
    return https.post(`admin/organizations/${organization.id}/collections/${collectionId}/items`, params);
  },
  deleteItem(collectionId, itemId) {
    return https.delete(`admin/organizations/${organization.id}/collections/${collectionId}/items/${itemId}`);
  },
  getItem(collectionId, itemId) {
    return https.get(`admin/organizations/${organization.id}/collections/${collectionId}/items/${itemId}`);
  },
  upadteItem(collectionId, itemId, params) {
    return https.put(`admin/organizations/${organization.id}/collections/${collectionId}/items/${itemId}`, params);
  },
}

export default collection
